.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button {
  border-radius: 20px !important;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

/* ::-webkit-scrollbar {
  display: none;
} */

:root {
  --amplify-primary-color: #3771c8;
  --amplify-primary-tint: #3771c8;
  --amplify-primary-shade: #3771c8;
  --amplify-secondary-tint: #3771c8;
}

amplify-button .button {
  border-radius: 30px !important;
}

amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
}

amplify-sign-in-button {
  display: none !important;
}

iframe:not([seamless]) {
  border: none;
}

@import "https://js.arcgis.com/4.21/@arcgis/core/assets/esri/themes/light/main.css";

.show-env-bg-button {
  color: white;
  line-height: 28px !important;
  margin: 0 !important;
  background-color: #3771c8;
  width: 100px !important;
  text-align: center !important;
  border-radius: 0 !important;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

#guiviewer3d-toolbar {
  text-align: right !important;
}

/* #navTools,
#toolbar-explodeTool,
#toolbar-measurementSubmenuTool {
  display: none !important;
} */

.myAwesomeExtensionIcon {
  background-image: url(/img/myAwesomeIcon.png);
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
}

.adsk-control-group {
  border-radius: 0 !important;
  box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px,
    rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px !important;
}

.adsk-button {
  margin: 0 !important;
  font-size: 14px !important;
}

.adsk-button-icon {
  font-size: 20 !important;
}

label.markup {
  position: absolute;
  padding: 0.5em;
  color: #adb5bd;
  background: #ffffff;
  opacity: 0.9;
  border-radius: 0 15px 15px 15px;
  z-index: 1;
  visibility: collapse;
  width: 0;
  height: 0;
}

div.markup {
  position: absolute;
  z-index: 1;
}

label.markup:hover {
  z-index: 2;
}

.adsk-viewing-viewer .docking-panel-title {
  font-size: 14px !important;
  font-weight: 500 !important;
  /* line-height: unset !important; */
}

.adsk-viewing-viewer.light-theme .docking-panel-close {
  background-size: 10px !important;
  background-color: white !important;
  height: 30px !important;
  background-position: 6px 12px !important;
}

.adsk-viewing-viewer,
.adsk-viewing-viewer .treeview leaf.property,
.adsk-viewing-viewer .treeview group,
.adsk-viewing-viewer .treeview .group,
.adsk-viewing-viewer .property-panel .aggregate-name {
  font-size: 10px !important;
}

/* .adsk-viewing-viewer .treeview .group > lmvheader, */
.adsk-viewing-viewer .treeview group > lmvheader,
.adsk-viewing-viewer .treeview leaf > lmvheader {
  height: unset !important;
}

/* .adsk-viewing-viewer .treeview .group, */
.adsk-viewing-viewer .treeview leaf {
  min-height: unset !important;
}

.adsk-viewing-viewer .treeview group.category {
  height: unset !important;
}
.adsk-viewing-viewer .property-panel .treeview group > lmvheader {
  padding-bottom: 0px !important;
  padding-top: 10px !important;
}

.adsk-viewing-viewer .property-panel .treeview lmvheader,
.adsk-viewing-viewer .property-panel .property-name,
.adsk-viewing-viewer .property-panel .property-value {
  padding-left: 2px !important;
}

.adsk-viewing-viewer .treeview lmvheader > icon {
  padding-top: 2px !important;
  padding-right: 6px !important;
  padding-bottom: 2px !important;
  padding-left: 6px !important;
  background-size: 6px !important;
}

.adsk-viewing-viewer .treeview .collapsed > lmvheader > icon {
  background-size: 4px !important;
}

.adsk-viewing-viewer .docking-panel-container-gradient {
  background: unset !important;
}

.adsk-viewing-viewer.light-theme .treeview div.visibility {
  background-position: 6px 12px !important;
  width: 20px !important;
  background-size: 14px !important;
  background-color: white !important;
}

.adsk-viewing-viewer .structure-panel .docking-panel-scroll {
  top: 50px !important;
}
