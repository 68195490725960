@import url(https://js.arcgis.com/4.21/@arcgis/core/assets/esri/themes/light/main.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button {
  border-radius: 20px !important;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

/* ::-webkit-scrollbar {
  display: none;
} */

:root {
  --amplify-primary-color: #3771c8;
  --amplify-primary-tint: #3771c8;
  --amplify-primary-shade: #3771c8;
  --amplify-secondary-tint: #3771c8;
}

amplify-button .button {
  border-radius: 30px !important;
}

amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  height: 100vh;
}

amplify-sign-in-button {
  display: none !important;
}

iframe:not([seamless]) {
  border: none;
}

.show-env-bg-button {
  color: white;
  line-height: 28px !important;
  margin: 0 !important;
  background-color: #3771c8;
  width: 100px !important;
  text-align: center !important;
  border-radius: 0 !important;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

#guiviewer3d-toolbar {
  text-align: right !important;
}

/* #navTools,
#toolbar-explodeTool,
#toolbar-measurementSubmenuTool {
  display: none !important;
} */

.myAwesomeExtensionIcon {
  background-image: url(/img/myAwesomeIcon.png);
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
}

.adsk-control-group {
  border-radius: 0 !important;
  box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px,
    rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px !important;
}

.adsk-button {
  margin: 0 !important;
  font-size: 14px !important;
}

.adsk-button-icon {
  font-size: 20 !important;
}

label.markup {
  position: absolute;
  padding: 0.5em;
  color: #adb5bd;
  background: #ffffff;
  opacity: 0.9;
  border-radius: 0 15px 15px 15px;
  z-index: 1;
  visibility: collapse;
  width: 0;
  height: 0;
}

div.markup {
  position: absolute;
  z-index: 1;
}

label.markup:hover {
  z-index: 2;
}

.adsk-viewing-viewer .docking-panel-title {
  font-size: 14px !important;
  font-weight: 500 !important;
  /* line-height: unset !important; */
}

.adsk-viewing-viewer.light-theme .docking-panel-close {
  background-size: 10px !important;
  background-color: white !important;
  height: 30px !important;
  background-position: 6px 12px !important;
}

.adsk-viewing-viewer,
.adsk-viewing-viewer .treeview leaf.property,
.adsk-viewing-viewer .treeview group,
.adsk-viewing-viewer .treeview .group,
.adsk-viewing-viewer .property-panel .aggregate-name {
  font-size: 10px !important;
}

/* .adsk-viewing-viewer .treeview .group > lmvheader, */
.adsk-viewing-viewer .treeview group > lmvheader,
.adsk-viewing-viewer .treeview leaf > lmvheader {
  height: unset !important;
}

/* .adsk-viewing-viewer .treeview .group, */
.adsk-viewing-viewer .treeview leaf {
  min-height: unset !important;
}

.adsk-viewing-viewer .treeview group.category {
  height: unset !important;
}
.adsk-viewing-viewer .property-panel .treeview group > lmvheader {
  padding-bottom: 0px !important;
  padding-top: 10px !important;
}

.adsk-viewing-viewer .property-panel .treeview lmvheader,
.adsk-viewing-viewer .property-panel .property-name,
.adsk-viewing-viewer .property-panel .property-value {
  padding-left: 2px !important;
}

.adsk-viewing-viewer .treeview lmvheader > icon {
  padding-top: 2px !important;
  padding-right: 6px !important;
  padding-bottom: 2px !important;
  padding-left: 6px !important;
  background-size: 6px !important;
}

.adsk-viewing-viewer .treeview .collapsed > lmvheader > icon {
  background-size: 4px !important;
}

.adsk-viewing-viewer .docking-panel-container-gradient {
  background: unset !important;
}

.adsk-viewing-viewer.light-theme .treeview div.visibility {
  background-position: 6px 12px !important;
  width: 20px !important;
  background-size: 14px !important;
  background-color: white !important;
}

.adsk-viewing-viewer .structure-panel .docking-panel-scroll {
  top: 50px !important;
}

.image-gallery {
    width: 100%;
    height: auto;
}

.image-gallery-slide img {
    width: 100%;
    height: 100vh;
    max-height: 300px;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
    min-height: 300px
}

.fullscreen .image-gallery-slide img {
    max-height: 100vh;
    object-fit: contain;
}

.image-gallery-left-nav {
    font-size: 1em;
}

.image-gallery-right-nav {
    font-size: 1em;
}

.image-gallery-fullscreen-button {
    font-size: 0.4em;
}

.image-gallery-svg {
    max-height: 36px !important;
    max-width: 36px !important;
  }
/* Marker tweaks */
.mapboxgl-popup-close-button {
    display: none;
  }
  
  .mapboxgl-popup-content {
    font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', Sans-serif;
    padding: 0;
    max-width: 300px;
  }
  
  .mapboxgl-popup-content-wrapper {
    padding: 1%;
  }
  
  .mapboxgl-popup-content h3 {
    background: #91c949;
    color: #fff;
    margin: 0;
    display: block;
    padding: 10px;
    border-radius: 3px 3px 0 0;
    font-weight: 700;
    margin-top: -15px;
  }
  
  .mapboxgl-popup-content h4 {
    margin: 0;
    display: block;
    padding: 10px;
    font-weight: 400;

  }
  
  .mapboxgl-popup-content div {
  }
  
  .mapboxgl-container .leaflet-marker-icon {
    cursor: pointer;
  }
  
  .mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
    margin-top: 15px;
  }
  
  .mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
    border-bottom-color: #91c949;
  }
.mapboxgl-ctrl button.mapboxgl-ctrl-layer .mapboxgl-ctrl-icon {
  background: no-repeat center/17px url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='layer-group' class='svg-inline--fa fa-layer-group fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23333' d='M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z'%3E%3C/path%3E%3C/svg%3E");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-layer-active .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='layer-group' class='svg-inline--fa fa-layer-group fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%2333B5E5' d='M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z'%3E%3C/path%3E%3C/svg%3E");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor {
  font-size: 14px;
  font-weight: bold;
  line-height: 28px;
  color: #333;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor-active {
  color: #33B5E5;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor-4 .mapboxgl-ctrl-icon::after {
  content: '4';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor-3 .mapboxgl-ctrl-icon::after {
  content: '3';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor-2 .mapboxgl-ctrl-icon::after {
  content: '2';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor-1 .mapboxgl-ctrl-icon::after {
  content: '1';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor-0 .mapboxgl-ctrl-icon::after {
  content: '0';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor--1 .mapboxgl-ctrl-icon::after {
  content: '-1';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor--2 .mapboxgl-ctrl-icon::after {
  content: '-2';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor--3 .mapboxgl-ctrl-icon::after {
  content: '-3';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor--4 .mapboxgl-ctrl-icon::after {
  content: '-4';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor--5 .mapboxgl-ctrl-icon::after {
  content: '-5';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor--6 .mapboxgl-ctrl-icon::after {
  content: '-6';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor--7 .mapboxgl-ctrl-icon::after {
  content: '-7';
}
* {
    box-sizing: border-box;
  }
  
  input,
  button {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
  }
  
  .AppWrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  @keyframes fade {
    from {
      opacity: 0;
      transform: scale3D(0.95, 0.95, 0.95);
    }
    to {
      opacity: 1;
      transform: scale3D(1, 1, 1);
    }
  }
  

  .Form {
    animation: fade 200ms ease-out;
  }
  
  .FormGroup {
    margin: 20px;
    padding: 0;
    border-style: none;
    background-color: #fff;
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #fff;
    border-radius: 4px;
  }
  
  .FormRow {
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
  
  .FormRow:first-child {
    border-top: none;
  }
  
  .FormRowLabel {
    width: 15%;
    min-width: 70px;
    padding: 11px 0;
    color: #999;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  @keyframes void-animation-out {
    0%,
    to {
      opacity: 1;
    }
  }
  .FormRowInput:-webkit-autofill {
    -webkit-text-fill-color: black;
    /* Hack to hide the default webkit autofill */
    -webkit-transition: background-color 100000000s;
    transition: background-color 100000000s;
    animation: 1ms void-animation-out;
  }
  
  .FormRowInput {
    font-size: 16px;
    width: 100%;
    padding: 11px 15px 11px 0;
    color: #000;
    background-color: transparent;
    animation: 1ms void-animation-out;
  }
  
  .FormRowInput:-ms-input-placeholder {
    color: #c5c5c5;
  }
  
  .FormRowInput::placeholder {
    color: #c5c5c5;
  }
  
  .StripeElement--webkit-autofill {
    background: transparent !important;
  }
  
  .StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
  }
  
  .SubmitButton {
    display: block;
    font-size: 16px;
    width: calc(100% - 30px);
    height: 40px;
    margin: 40px 15px 0;
    background-color: #0078D4;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #0078D4;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    will-change: transform, background-color, box-shadow;
  }
  
  .SubmitButton:active {
    background-color: #0078D4;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #0078D4;
    transform: scale(0.99);
  }
  
  .SubmitButton.SubmitButton--error {
    transform: translateY(15px);
  }
  .SubmitButton.SubmitButton--error:active {
    transform: scale(0.99) translateY(15px);
  }
  
  .SubmitButton:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: #7795f8;
    box-shadow: none;
  }
  
  .ErrorMessage {
    color: #000;
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    font-size: 13px;
    margin-top: 0px;
    width: 100%;
    transform: translateY(-15px);
    opacity: 0;
    animation: fade 150ms ease-out;
    animation-delay: 50ms;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
  }
  
  .ErrorMessage svg {
    margin-right: 10px;
  }
  
  .Result {
    margin-top: 50px;
    text-align: center;
    animation: fade 200ms ease-out;
  }
  
  .ResultTitle {
    color: green;
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 17px;
    text-align: center;
  }
  
  .ResultMessage {
    color: green;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 1.6em;
    text-align: center;
  }
  
  .ResetButton {
    border: 0;
    cursor: pointer;
    background: transparent;
  }
